<template>
  <div class="indentList" v-if="modelList">
    <ul class="list">
      <li
        class="item"
        @click="getOrderDetails(item.OrderNumber)"
        v-for="item in modelList"
        :key="item.OrderNumber"
      >
        <indent-info :itemInfo="item"></indent-info>
        <cargo-list :item="item.OederProductList"></cargo-list>
        <receiving :itemInfo="item"></receiving>
        <!-- 退款给买家 -->
        <!-- <van-row
          type="flex"
          align="center"
          justify="space-between"
          class="handle"
          v-if="item.State === 20"
        >
          <van-col class="handleTitle">{{
            $t.remindSupplierOfShipment
          }}</van-col>
          <van-col class="handleBtBox">
            <button class="handleBt">{{ $t.orderEdit.refundToBuyer }}</button>
          </van-col>
        </van-row> -->
        <!-- 等待买家付款中 -->
        <!-- <van-row
          type="flex"
          align="center"
          justify="space-between"
          class="handle"
          v-else-if="item.State === 1"
        >
          <van-col class="handleTitle">{{
            $t.orderEdit.waitingForTheBuyerToPay
          }}</van-col>
          <van-col class="handleBtBox">
            <button class="handleBt payHandleBt">
              {{ $t.orderEdit.closeOrder }}
            </button>
          </van-col>
        </van-row> -->
        <!-- 等待买家确认收款 -->
        <!-- <van-row
          type="flex"
          align="center"
          justify="space-between"
          class="handle"
          v-else
        >
          <van-col class="handleTitle">{{
            $t.orderEdit.WaitingConfirmCollection
          }}</van-col>
          <van-col class="handleBtBox">
            <button class="handleBt successHandleBt">
              {{ $t.orderEdit.confirmReceipt }}
            </button>
          </van-col>
        </van-row> -->
      </li>
    </ul>
  </div>
</template>
<script>
import IndentInfo from "./IndentInfo";
import CargoList from "@components/common/CargoList";
import Receiving from "./Receiving";
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);

export default {
  name: "IndentList",
  props: {
    modelList: {
      type: Array,
    },
  },
  components: {
    IndentInfo,
    CargoList,
    Receiving,
  },
  data() {
    return {
      handleType: 0,
    };
  },
  methods: {
    //跳转订单详情
    getOrderDetails(OrderNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "indentDetails",
        name: "indentDetails",
        query: [
          {
            name: "OrderNumber",
            value: OrderNumber,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.indentList {
  width: 100%;
  .list {
    list-style: none;
    margin: 0 auto;
    width: 92%;
    .item {
      background-color: #ffffff;
      margin: 0;
      margin-top: 16px;
      list-style: none;
      @include publicBox;
      .handle {
        padding: 18px 30px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        .handleTitle {
          width: 50%;
          color: #ff5852;
        }
        .handleBtBox {
          width: 50%;
          text-align: right;
          .handleBt {
            width: 150px;
            padding: 18px 0;
            border-radius: 36px;
            border: 0.025rem solid #25b9ff;
            color: #25b9ff;
            font-size: 24px;
            background-color: #ffffff;
          }
          .payHandleBt {
            border: 0.025rem solid #999999;
            color: #999999;
          }
          .successHandleBt {
            border: 0.025rem solid #43cd9a;
            color: #ffffff;
            background-color: #43cd9a;
          }
        }
      }
    }
  }
}
</style>
