<template>
  <div class="cargoList" v-if="item">
    <ul class="listBox">
      <li class="cargoitem" v-for="(newitem, index) in item" :key="index">
        <figure class="cargoImg">
          <img
            class="cargoImgIcon"
            :src="newitem.ProductPhoto && imgUrlFilter(newitem.ProductPhoto)"
          />
        </figure>
        <div class="cargoInfo">
          <p class="cargoInfoTitle">{{ newitem.ProductName }}</p>
          <section class="cargoInfoSpec">
            <p class="cargoInfoSpecLeft"></p>
            <p class="cargoInfoSpecRight">
              ${{ newitem.PayProductPrice }}
              <span class="cargoInfoSpecNum">x{{ newitem.Number }}</span>
            </p>
          </section>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);

export default {
  name: "CargoList",
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      let string = "";
      if (value.indexOf("|") == -1) {
        string = this.$api.formatImageUrl(value);
      } else {
        let list = value.split("|");
        string = this.$api.formatImageUrl(list[0]);
      }
      console.log(string);
      //return this.$api.formatImageUrl(value);
      return string;
    },
    //跳转详情页
    // getDetails() {
    //   this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
    //     isParams: true,
    //     path: "indentDetails",
    //     name: "indentDetails",
    //     query: []
    //   });
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.cargoList {
  width: 100%;
  .listBox {
    margin: 0;
    padding: 0;
    list-style: none;
    .cargoitem {
      list-style: none;
      margin: 0;
      // padding: 30px 0;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      padding: 20px;
      border-bottom: 1px solid #f6f6f6;
      @include publicFlex;
      .cargoImg {
        margin: 0;
        padding: 0;
        width: 25%;
        @include publicFlex;
        .cargoImgIcon {
          width: 100%;
          width: 60px;
          height: 60px;
          object-fit: cover;
          -webkit-object-fit: cover;
        }
      }
      .cargoInfo {
        width: 75%;
        .cargoInfoTitle {
          margin: 0;
          padding: 0;
          width: 100%;
          font-size: 14px;
          @include spanTwo;
        }
        .cargoInfoSpec {
          margin-top: 18px;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          .cargoInfoSpecLeft {
            margin: 0;
            padding: 0;
            color: #a7a7a7;
            min-width: 75%;
            word-break: break-word;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
          }
          .cargoInfoSpecRight {
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: #000000;
            display: inline-block;
            width: 25%;
            vertical-align: middle;
            text-align: right;
            .cargoInfoSpecNum {
              color: #999999;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
